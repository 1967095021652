/* REACT-SELECT FIX */
.Select-menu-outer {
    z-index: 1000 !important;
}

/*GT CUSTOM BUTTON THEME START*/
.btn-bootstrap-primary {
    border-color: #377cb3;
    color: #377cb3; }
.btn-bootstrap-primary:hover, .btn-bootstrap-primary:focus, .btn-bootstrap-primary:active, .btn-bootstrap-primary.active, .btn-bootstrap-primary:active:focus, .btn-bootstrap-primary:active:hover, .btn-bootstrap-primary.active:focus, .btn-bootstrap-primary.active:hover,
.open > .btn-bootstrap-primary.dropdown-toggle {
    background-color: transparent;
    color: #377cb3;
    border-color: #377cb3; }
.btn-bootstrap-primary.disabled, .btn-bootstrap-primary.disabled:hover, .btn-bootstrap-primary.disabled:focus, .btn-bootstrap-primary.disabled.focus, .btn-bootstrap-primary.disabled:active, .btn-bootstrap-primary.disabled.active, .btn-bootstrap-primary:disabled, .btn-bootstrap-primary:disabled:hover, .btn-bootstrap-primary:disabled:focus, .btn-bootstrap-primary:disabled.focus, .btn-bootstrap-primary:disabled:active, .btn-bootstrap-primary:disabled.active, .btn-bootstrap-primary[disabled], .btn-bootstrap-primary[disabled]:hover, .btn-bootstrap-primary[disabled]:focus, .btn-bootstrap-primary[disabled].focus, .btn-bootstrap-primary[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-bootstrap-primary,
fieldset[disabled] .btn-bootstrap-primary:hover,
fieldset[disabled] .btn-bootstrap-primary:focus,
fieldset[disabled] .btn-bootstrap-primary.focus,
fieldset[disabled] .btn-bootstrap-primary:active,
fieldset[disabled] .btn-bootstrap-primary.active {
    background-color: transparent;
    border-color: #377cb3; }
.btn-bootstrap-primary.btn-fill {
    color: #FFFFFF;
    background-color: #377cb3;
    opacity: 1;
    filter: alpha(opacity=100); }
.btn-bootstrap-primary.btn-fill:hover, .btn-bootstrap-primary.btn-fill:focus, .btn-bootstrap-primary.btn-fill:active, .btn-bootstrap-primary.btn-fill.active,
.open > .btn-bootstrap-primary.btn-fill.dropdown-toggle {
    background-color: #377cb3;
    color: #FFFFFF; }
.btn-bootstrap-primary.btn-fill .caret {
    border-top-color: #FFFFFF; }
.btn-bootstrap-primary .caret {
    border-top-color: #377cb3; }
/*CUSTOM BUTTON THEME END*/

.nav-icons > li.active a {
    color: #3a7eb0; }

.react-dual-listbox {
    height: 410px;
}

.text-roku, .text-roku:hover {
    color: #69308d !important; }
/* GT ABOVE SETS COLOR FOR NAV TAB*/

.btn-info {
    border-color: #377cb3;
    color: #377cb3; }
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info:active:focus, .btn-info:active:hover, .btn-info.active:focus, .btn-info.active:hover,
.open > .btn-info.dropdown-toggle {
    background-color: transparent;
    color: #377cb3;
    border-color: #377cb3; }
.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info:disabled, .btn-info:disabled:hover, .btn-info:disabled:focus, .btn-info:disabled.focus, .btn-info:disabled:active, .btn-info:disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
    background-color: transparent;
    border-color: #377cb3; }
.btn-info.btn-fill {
    color: #FFFFFF;
    background-color: #377cb3;
    opacity: 1;
    filter: alpha(opacity=100); }
.btn-info.btn-fill:hover, .btn-info.btn-fill:focus, .btn-info.btn-fill:active, .btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
    background-color: #377cb3;
    color: #FFFFFF; }
.btn-info.btn-fill .caret {
    border-top-color: #FFFFFF; }
.btn-info .caret {
    border-top-color: #377cb3; }

.text-info, .text-info:hover {
    color: #377cb3 !important; }

.has-error .help-block {
    color: red;
}

.has-error .form-control, .form-control.error, .has-error .form-control:focus {
    color: #FB404B;
    /*background-color: rgb(255, 237, 238);*/
    border-color: rgba(251, 64, 75, 0.64);
}

/*MAKE INPUT BUTTON BIGGER TO BETTER ALIGN*/
.input-group-btn .btn {
    border-width: 2px;
    padding: 8px 16px;
}

/*Reduced padding to make it more compact*/
.tab-pane {
    padding: 0px 0;
}

/*Reduced margin to make it more compact*/
.card {
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
    background-color: #FFFFFF;
    margin-bottom: 15px;
}



/*Dual List box*/
.rdl-filter-container .rdl-control-label {
    position: static;
}

.rdl-has-filter .rdl-actions {
    padding-top: 70px;
}

/*PAGINATION*/
.pagination > li > a, .pagination > li > span, .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-radius: 0;
    margin: 0px 1px;
    color: #353535;
}

.pagination > li.active > a, .pagination > li.active > span, .pagination > li.active > a:hover, .pagination > li.active > span:hover, .pagination > li.active > a:focus, .pagination > li.active > span:focus {
    background-color: #377cb3;
    border: 0;
    color: #FFFFFF;
    padding: 7px 13px;
}

.radio input[type="radio"]:checked:not(:disabled):hover + label::after, .radio input[type="radio"]:checked + label::after {
    color: #377cb3;
}

/*Reduced margin on sidebar to make it more compact*/
.sidebar .nav li > a {
    padding-left: 10px;
    padding-right: 10px;
    margin: 1px 15px 0px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.7);
    opacity: .86;
}

a {
    color: #377cb3;
}

.Select, .Select div, .Select input, .Select span {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 5px;
}

/*.sidebar .nav li.active > a {*/
    /*color: #ffa534;*/
    /*opacity: 1;*/
    /*background: rgba(255, 255, 255, 0.23);*/
/*}*/

/*!*    background-color: #282a39; *!*/
/*!*Reduced line-height on sidebar to make it more compact*!*/
/*.sidebar .nav p {*/
    /*font-family: 'Poppins', sans-serif;*/
    /*margin: 0;*/
    /*line-height: 20px;*/
    /*font-size: 13px;*/
    /*!*font-weight: 500;*!*/
    /*text-transform: none;*/
    /*white-space: nowrap;*/
    /*position: relative;*/
    /*color: rgba(255, 255, 255, 0.7);*/
    /*-webkit-transform: translate3d(0px, 0, 0);*/
    /*-ms-transform: translate3d(0px, 0, 0);*/
    /*transform: translate3d(0px, 0, 0);*/
    /*display: block;*/
    /*height: auto;*/
    /*opacity: 1;*/
/*}*/

/*!*Reduced line-height on sidebar images to make it more compact*!*/
/*.sidebar .nav i {*/
    /*font-size: 20px;*/
    /*float: left;*/
    /*margin-right: 10px;*/
    /*line-height: 20px;*/
    /*width: 30px;*/
    /*text-align: center;*/
/*}*/

/*.sidebar .sidebar-wrapper .nav ul > li > a i, .sidebar .sidebar-wrapper .user .info ul > li > a i {*/
    /*font-size: 13px;*/
    /*line-height: 24px;*/
    /*width: 26px;*/
/*}*/

/*.sidebar .sidebar-wrapper .nav ul > li > a .sidebar-normal, .sidebar .sidebar-wrapper .user .info ul > li > a .sidebar-normal {*/
    /*margin: 0;*/
    /*position: relative;*/
    /*-webkit-transform: translate3d(0px, 0, 0);*/
    /*-ms-transform: translate3d(0px, 0, 0);*/
    /*transform: translate3d(0px, 0, 0);*/
    /*opacity: 1;*/
    /*white-space: nowrap;*/
    /*text-transform: none;*/
    /*display: block;*/
    /*font-size: 13px;*/
    /*font-family: 'Poppins', sans-serif;*/
/*}*/

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #ccc;
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: #f3f1f1;
    border-radius: .25em;
    padding: 1em;
    box-shadow: 0 3px 0 #ddd;
}

.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 124%;
    top: 6px;
    color: #333;
    font-size: 1.3rem;
}

.login-page .card {
    box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.sidebar:before, .bootstrap-navbar:before {
    opacity: .33;
    background: #990099;
}

.main-panel .main-content {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

.sidebar-mini .sidebar, .sidebar-mini .sidebar .sidebar-wrapper {
    width: 78px;
}

.has-warning .form-control {
    border-color: #ffa534;
    background-color: #ffa53433;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.has-warning .checkbox, .has-warning .checkbox-inline, .has-warning .control-label, .has-warning .help-block, .has-warning .radio, .has-warning .radio-inline, .has-warning.checkbox label, .has-warning.checkbox-inline label, .has-warning.radio label, .has-warning.radio-inline label {
    color: #ffa534;
}

.has-warning .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    /*color: #737373;*/
    /* min-height: 10px; */
    /* margin-bottom: 10px; */
    padding: 5px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}


.has-error .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    /*color: #737373;*/
    /* min-height: 10px; */
    /* margin-bottom: 10px; */
    padding: 5px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}

.has-error .help-block .glyphicon {
    visibility: visible;
}

.has-warning .help-block .glyphicon {
    visibility: visible;
}

.help-block .glyphicon {
    visibility: hidden;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: #377cb3;
    color: #FFFFFF;
}

/*With Borders*/
/*.nav-pills > li > a {*/
    /*border: 1px solid #377cb3;*/
    /*border-radius: 4px;*/
    /*color: #377cb3;*/
    /*margin-left: -1px;*/
/*}*/

/*NO Borders*/
.nav-pills > li > a {
    border: 1px solid rgba(55, 124, 179, 0);
    border-radius: 4px;
    color: #333;
    margin-left: -1px;
}

.nav-pills > li:first-child > a {
    border-radius: 4px 4px 4px 4px;
    margin: 0;
}

.nav-pills > li:last-child > a {
    border-radius: 4px 4px 4px 4px;
}